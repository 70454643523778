/* eslint-disable react-hooks/rules-of-hooks */
import { APIClient, ClientOptions, FindAllRequest } from '@ch-apptitude-icc/common/shared/api-client';
import { Entity } from '@ch-apptitude-icc/common/shared/entities';
import { QueryClient, useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { UseQueryOptions } from '@tanstack/react-query/src/types';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { UseGetOptions, UseMutationOptions, AbstractApiCallHelper } from './AbstractApiCallHelper';
import { Environment } from './Environment';
import { InvalidationMode, QueryEntity, ReactQueryKeys } from './ReactQueryKeys';
import type { GenerateKeys, Keys } from './ReactQueryKeys';

export interface APIListResponse<EntityType> {
  results: Array<EntityType>;
  total: number;
}

export type APIDetailResponse<EntityType> = EntityType;

export function ApiFactory<
  FrontEntity extends Entity<string | number>,
  ClientConstructorType extends (opts: ClientOptions<FrontEntity>) => APIClient<FrontEntity, never>,
  CustomKeys extends Keys<FrontEntity> = Keys<FrontEntity>,
  ClientType extends APIClient<FrontEntity, never> = ReturnType<ClientConstructorType>,
  ExcludeFunctions extends string =
    | ('create' extends keyof ClientType ? never : 'useAddOne')
    | ('update' extends keyof ClientType ? never : 'useUpdateOne')
    | ('delete' extends keyof ClientType ? never : 'useDeleteOne')
    | ('findAll' extends keyof ClientType ? never : 'useGetList' | 'useGetAll' | 'useGetMap')
    | ('findOne' extends keyof ClientType ? never : 'useGetOne'),
>(): {
  new (
    queryClient: QueryClient,
    apiCallHelper: AbstractApiCallHelper,
    clientBuilder: (opts: AxiosRequestConfig<FrontEntity> & Record<string, unknown>) => ClientType,
    generateKeys?: GenerateKeys<FrontEntity, CustomKeys>,
  ): Omit<ApiFactoryBase<FrontEntity, ClientType, CustomKeys>, ExcludeFunctions> & {
    api: ClientType & APIClient<FrontEntity>;
    apiCallHelper: AbstractApiCallHelper;
    rqKeys: ReactQueryKeys<FrontEntity, CustomKeys>;
  };
} {
  class FactoryClass extends ApiFactoryBase<FrontEntity, ClientType, CustomKeys> {}

  // @ts-expect-error we pretend that the class is not abstract
  return FactoryClass;
}

type ClientConstructor<T extends Entity<string | number>> = (opts: ClientOptions<T>) => APIClient<T, never>;

type EntityTypeFromConstructor<Ctor extends ClientConstructor<unknown & Entity<string | number>>> = Ctor extends (
  opts: unknown,
) => APIClient<infer ET extends Entity<string | number>, 'findOne'>
  ? ET
  : Ctor extends (opts: unknown) => APIClient<infer ET extends Entity<string | number>, 'findAll'>
  ? ET
  : Ctor extends (opts: unknown) => APIClient<infer ET extends Entity<string | number>, 'update'>
  ? ET
  : Ctor extends (opts: unknown) => APIClient<infer ET extends Entity<string | number>, 'delete'>
  ? ET
  : Ctor extends (opts: unknown) => APIClient<infer ET extends Entity<string | number>, 'create'>
  ? ET
  : never;

export function YupApiFactory<
  ClientConstructorType extends ClientConstructor<Entity<string | number>>,
  CustomKeys extends Keys<EntityTypeFromConstructor<ClientConstructorType>> = Keys<
    EntityTypeFromConstructor<ClientConstructorType>
  >,
  ClientType = ReturnType<ClientConstructorType>,
  ExcludeFunctions extends string =
    | ('create' extends keyof ClientType ? never : 'useAddOne')
    | ('update' extends keyof ClientType ? never : 'useUpdateOne')
    | ('delete' extends keyof ClientType ? never : 'useDeleteOne')
    | ('findAll' extends keyof ClientType ? never : 'useGetList' | 'useGetAll' | 'useGetMap')
    | ('findOne' extends keyof ClientType ? never : 'useGetOne'),
>(): {
  new (
    queryClient: QueryClient,
    apiCallHelper: AbstractApiCallHelper,
    clientBuilder: (
      opts: AxiosRequestConfig<EntityTypeFromConstructor<ClientConstructorType>> & Record<string, unknown>,
    ) => ReturnType<ClientConstructorType>,
    generateKeys?: GenerateKeys<EntityTypeFromConstructor<ClientConstructorType>, CustomKeys>,
  ): Omit<
    ApiFactoryBase<EntityTypeFromConstructor<ClientConstructorType>, ReturnType<ClientConstructorType>, CustomKeys>,
    ExcludeFunctions
  > & {
    api: ReturnType<ClientConstructorType> & APIClient<EntityTypeFromConstructor<ClientConstructorType>>;
    apiCallHelper: AbstractApiCallHelper;
    rqKeys: ReactQueryKeys<EntityTypeFromConstructor<ClientConstructorType>, CustomKeys>;
  };
} {
  class FactoryClass extends ApiFactoryBase<
    EntityTypeFromConstructor<ClientConstructorType>,
    ReturnType<ClientConstructorType>,
    CustomKeys
  > {}

  // @ts-expect-error we pretend that the class is not abstract
  return FactoryClass;
}
/**
 * When read, an entity always has its id
 */
export type FrontEntityRead<T extends QueryEntity> = T & Pick<Required<T>, 'id'>;

export class ApiFactoryBase<
  FrontEntity extends QueryEntity,
  ClientType extends APIClient<FrontEntity, never> = APIClient<FrontEntity>,
  CustomKeys extends Keys<FrontEntity> = Keys<FrontEntity>,
  IdType extends Exclude<FrontEntity['id'], undefined | null> = Exclude<FrontEntity['id'], undefined | null>,
> {
  protected api!: ClientType & APIClient<FrontEntity>;

  protected rqKeys!: ReactQueryKeys<FrontEntity, CustomKeys>;

  constructor(
    public queryClient: QueryClient,
    private readonly apiCallHelper: AbstractApiCallHelper,
    private clientBuilder: (opts: AxiosRequestConfig<FrontEntity> & Record<string, unknown>) => ClientType,
    generateKeys?: GenerateKeys<FrontEntity, CustomKeys>,
  ) {
    this.rqKeys = new ReactQueryKeys(queryClient, this.constructor.name, generateKeys);
    this.buildClientApi();
  }

  /**
   * Default request timeout (in ms)
   */
  public static get timeout(): number {
    // as `get` to be readonly
    return 20000;
  }

  public useGetList<ReturnType = APIListResponse<FrontEntityRead<FrontEntity>>>(
    parameters?: FindAllRequest<FrontEntity>,
    options?: UseGetOptions<ReturnType, APIListResponse<FrontEntity>>,
    keepPreviousData = true,
  ): UseQueryResult<ReturnType, AxiosError> {
    return useQuery({
      enabled: options?.enabled ?? true,
      keepPreviousData: options?.keepPreviousData ?? keepPreviousData,
      onError: error => this.apiCallHelper.onError(error, options?.onError),
      onSuccess: data => options?.onSuccess && options?.onSuccess(data),
      queryFn: ({ signal }) => this.api.findAll(parameters, { signal }).then(_ => _.data),
      queryKey: this.rqKeys.keys.list(parameters),

      select: options?.select,

      refetchInterval: options?.disableRefetching ? false : undefined,
      refetchOnMount: options?.disableRefetching ? false : undefined,
      refetchOnWindowFocus: options?.disableRefetching ? false : undefined,
      refetchOnReconnect: options?.disableRefetching ? false : undefined,
    });
  }

  /**
   * Returns the list of all entities matching a condition
   */
  useGetAll<RT = Array<FrontEntity>>(
    parameters?: Omit<FindAllRequest<FrontEntity>, 'page' | 'pageSize'>,
    options?: UseGetOptions<RT, Array<FrontEntity>>,
  ): UseQueryResult<RT> {
    return this.useGetList(
      { ...parameters, page: 0, pageSize: Environment.CURRENT.unboundedPageSize },
      { ...options, select: data => (options?.select ? options.select(data.results) : (data.results as RT)) },
    );
  }

  /**
   * Returns a map of all entities matching a condition, mapped by id
   */
  useGetMap<RT = Map<FrontEntity['id'], FrontEntity>>(
    parameters?: Omit<FindAllRequest<FrontEntity>, 'page' | 'pageSize'>,
    options?: UseGetOptions<RT, Map<FrontEntity['id'], FrontEntity>>,
  ): UseQueryResult<RT> {
    return this.useGetAll(parameters, {
      ...options,
      select: data => {
        const stepSelect = new Map(data.map(v => [v.id, v]));

        return options?.select ? options.select(stepSelect) : (stepSelect as RT);
      },
    });
  }

  public useGetOne<ReturnType = FrontEntityRead<FrontEntity>>(
    id: IdType,
    options?: UseGetOptions<ReturnType, FrontEntity>,
  ): UseQueryResult<ReturnType, AxiosError> {
    return useQuery({
      ...this.getOneQuery<ReturnType>(id, options?.select),

      refetchInterval: options?.disableRefetching ? false : options?.refetchInterval,
      refetchOnMount: options?.disableRefetching ? false : undefined,
      refetchOnWindowFocus: options?.disableRefetching ? false : undefined,
      refetchOnReconnect: options?.disableRefetching ? false : undefined,

      enabled: options?.enabled ?? true,
      onError: error => this.apiCallHelper.onError(error, options?.onError),
      onSuccess: data => options?.onSuccess && options?.onSuccess(data),
    });
  }

  public getOneQuery<RT = FrontEntityRead<FrontEntity>>(
    id: IdType,
    select?: (src: FrontEntity) => RT,
  ): UseQueryOptions<FrontEntity, AxiosError, RT> {
    return {
      queryFn: ({ signal }) => this.api.findOne(id, { signal }).then(_ => _.data),
      queryKey: this.rqKeys.keys.detail(id),
      select,
    };
  }

  public useAddOne(
    options?: UseMutationOptions<APIDetailResponse<FrontEntity>>,
  ): UseMutationResult<
    AxiosResponse<FrontEntity>,
    AxiosError,
    ClientType extends { create: (v: infer T) => unknown } ? T : never
  > {
    return useMutation({
      mutationFn: body => this.api.create(body as Omit<FrontEntity, 'id'>),
      onError: error => this.apiCallHelper.onError(error, options?.onError),
      onSuccess: data =>
        this.apiCallHelper.onSuccessMutation(
          data,
          async () => {
            await this.rqKeys.invalidQuery('lists');
          },
          options?.onSuccess,
        ),
    });
  }

  public useUpdateOne(
    overrideId?: IdType,
    options?: UseMutationOptions<APIDetailResponse<FrontEntity>>,
  ): UseMutationResult<
    AxiosResponse<FrontEntity>,
    AxiosError,
    ClientType extends { update: (id: IdType, entity: infer T) => unknown } ? T & { id?: IdType } : never
  > {
    return useMutation({
      mutationFn: req => {
        const id = typeof req === 'object' && req && 'id' in req ? req.id : undefined;
        const body =
          typeof req === 'object' && req && 'id' in req
            ? (() => {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const { id: _, ...b } = req;
                return b;
              })()
            : req;

        return id || overrideId
          ? this.api.update((id ?? overrideId) as unknown as IdType, body as unknown as Partial<FrontEntity>)
          : Promise.reject();
      },
      onError: error => this.apiCallHelper.onError(error, options?.onError),
      onSuccess: data =>
        this.apiCallHelper.onSuccessMutation(
          data,
          async () => {
            await this.rqKeys.invalidQuery('detail', [data.data.id]);
            await this.rqKeys.invalidQuery('lists');
          },
          options?.onSuccess,
        ),
    });
  }

  public invalidateOne(id: number, mode?: InvalidationMode): Promise<void> {
    return this.rqKeys.invalidQuery('detail', [id], mode);
  }

  public invalidateList(id: number, mode?: InvalidationMode): Promise<void> {
    return this.rqKeys.invalidQuery('lists', undefined, mode);
  }

  public useDeleteOne<Params = unknown>(
    options?: UseMutationOptions<APIDetailResponse<IdType>>,
  ): UseMutationResult<IdType, AxiosError, IdType | ({ id: IdType } & Params)> {
    return useMutation({
      mutationFn: async idOrParams => {
        if (typeof idOrParams === 'object') {
          const { id, ...params } = idOrParams;
          await this.api.delete(id, { params });
          return id;
        }
        await this.api.delete(idOrParams);

        return idOrParams;
      },
      onError: error => this.apiCallHelper.onError(error, options?.onError),
      onSuccess: id =>
        this.apiCallHelper.onSuccessDeleteMutation(
          id,
          async () => {
            await this.rqKeys.invalidQuery('lists');
            await this.rqKeys.invalidQuery('detail', [id]);
          },
          options?.onSuccess,
        ),
    });
  }

  private buildClientApi(): void {
    const axiosConfig = {
      baseURL: Environment.CURRENT.baseUrl,
      timeout: ApiFactoryBase.timeout,
      withCredentials: true,
    };
    this.api = this.clientBuilder(axiosConfig) as APIClient<FrontEntity> & ClientType;
  }
}
