import { OmitType } from '@ch-apptitude-icc/common/shared/type-utils';
import {
  IsBoolean,
  IsDefined,
  IsIn,
  IsOptional,
  MaxDate,
  requireFieldsAsValidationError,
  RequirementValidator,
} from '@ch-apptitude-icc/common/shared/validation';
import { maxDate, validateSync, ValidationError } from 'class-validator';
import { addHours } from 'date-fns';
import { DeepPartial } from 'typeorm';
import { errors } from '../../../errors';
import { OrderBilledTo, OrderSamplingStatus } from '../../enums';
import { Insurer } from '../../insurer.entity';
import { Order } from '../order';

export interface OrderCreateValidateOptions {
  /**
   * Run the class validator
   */
  classValidator?: boolean;

  /**
   * These helps to validate fields with relation.
   * @warning not in the DTO; Some of these values are not allowed in `validatorSync`.
   */
  extra?: {
    /**
     *  insurer used to validate the order
     */
    insurer?: Insurer;

    allowAnonymous?: boolean;
  };
}

export class OrderCreateDto extends OmitType(Order, [
  'id',
  'doctor',
  'doctorId',
  'foundStd',
  'iccComments',
  'requestDate',
  'lavigny',
  'ip',
  'covid',
  'profa',
  'isProfa',
  'isCovid',

  // Fields that must be remapped from billedTo
  'thirdPartyPayer',
  'invoicePatient',

  // Fields that must be remapped from sampling status
  'sampleAtPatientHome',
  'sampleAtTheLab',
]) {
  @IsBoolean()
  @IsOptional()
  insuranceBillingNoEmail?: boolean;

  public static validatePatientStep(
    order: DeepPartial<OrderCreateDto>,
    allowAnonymous: boolean,
    isProfaDoctor?: boolean,
  ): string[] {
    if (isProfaDoctor && !order.reference) {
      return [`reference:${IsDefined.validatorName}`];
    }

    if (!order.patient) {
      return [`patient:${IsDefined.validatorName}`];
    }

    if (order.patient.anonymous && !allowAnonymous) {
      return [`patient.anonymous:${IsIn.validatorName};false`];
    }

    if (!order.patient.anonymous) {
      return requireFieldsAsValidationError({ firstname: true, lastname: true }, order.patient);
    }

    return [];
  }

  public static validateFinalStep(order: DeepPartial<OrderCreateDto>): string[] {
    const sampleDate: Date | undefined | null =
      typeof order.sampleDate === 'string' ? new Date(order.sampleDate) : order.sampleDate;

    switch (order.samplingStatus) {
      case OrderSamplingStatus.SAMPLE_LATER:
      case OrderSamplingStatus.SAMPLE_AT_LAB:
        return [];

      case OrderSamplingStatus.SAMPLING_DONE:
        // Check that max-date is not in the future
        if (!sampleDate) {
          return [`sampleDate:${IsDefined.validatorName}`];
        }

        if (!maxDate(sampleDate, addHours(new Date(), 8))) {
          return [`sampleDate:${MaxDate.validatorName}`];
        }

        return [];

      default:
        return [`samplingStatus:${errors.generic.validation.invalid}`];
    }
  }

  public static validateOrderStep(
    order: DeepPartial<OrderCreateDto>,
    insurer?: { thirdPartyPayer?: boolean },
  ): string[] {
    let reqFields: RequirementValidator<OrderCreateDto> = {};

    switch (order.billedTo) {
      case OrderBilledTo.INSURANCE:
        // We cannot determine the mandatory fields without the thirdPartyPayer,
        //  but currently set as false if missing TODO ID-392: no required fields when undefined?
        reqFields =
          insurer?.thirdPartyPayer && !order.insuranceBillingNoEmail
            ? {
                patient: {
                  insurerId: true,
                  emailAddress: true,
                  socialSecurityNumber: true,
                  telephoneNumber: true,
                },
              }
            : {
                patient: {
                  socialSecurityNumber: !!insurer?.thirdPartyPayer,
                  address: {
                    address1: true,
                    city: true,
                    countryCode: true,
                    postcode: true,
                  },
                  insurerId: true,
                },
              };
        break;

      case OrderBilledTo.PATIENT:
        reqFields = { patient: { address: { address1: true, city: true, countryCode: true, postcode: true } } };
        break;

      case OrderBilledTo.OTHER_BILLING:
        reqFields = {
          invoicing: { address1: true, firstname: true, lastname: true, countryCode: true, postcode: true, city: true },
        };
        break;

      default:
        // Already tested before
        break;
    }

    if (order.copyToPatient) {
      // This is not intern level code, we really need to check if patient is true and not just defined
      if (reqFields.patient === true) {
        reqFields = { ...reqFields, patient: { emailAddress: true } };
      } else {
        reqFields = { ...reqFields, patient: { ...reqFields.patient, emailAddress: true } };
      }
    }

    if (order.secondDoctorData) {
      reqFields = {
        ...reqFields,
        secondDoctorData: {
          firstname: true,
          lastname: true,
          address: { address1: true, city: true, countryCode: true, postcode: true },
        },
      };
    }

    return requireFieldsAsValidationError(reqFields, order);
  }

  public static validate(
    order: OrderCreateDto,
    options: OrderCreateValidateOptions = { classValidator: false },
  ): Array<ValidationError | string> {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const errors: Array<ValidationError | string> = options.classValidator ? validateSync(order) : [];

    // TODO ID-392: add more?

    if (order.patient) {
      errors.push(...this.validatePatientStep(order, options.extra?.allowAnonymous ?? false));
    }

    if (order.billedTo) {
      errors.push(...this.validateOrderStep(order, options.extra?.insurer));
    }

    if (order.samplingStatus) {
      errors.push(...this.validateFinalStep(order));
    }

    return errors;
  }
}
