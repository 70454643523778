import {
  AbstractLablinkOrder,
  MagicValues,
  dateTransformer,
  booleanTransformer,
} from '@ch-apptitude-icc/common/shared/entities';
import {
  ArrayNotEmpty,
  IsArray,
  IsBoolean,
  IsDate,
  IsDateString,
  IsDefined,
  IsEnum,
  IsIn,
  IsIP,
  IsNotIn,
  IsObject,
  IsOptional,
  IsOptionalIf,
  MaxLength,
  ValidateNested,
} from '@ch-apptitude-icc/common/shared/validation';
import { Exclude, plainToInstance, Transform, Type } from 'class-transformer';
import { Column, CreateDateColumn, Index, PrimaryGeneratedColumn } from 'typeorm';

import { Invoicing } from './invoicing';
import { OrderCovidFields } from './order-covid.fields';
import { OrderLavignyFields } from './order-lavigny.fields';
import { OrderPatientFields } from './order-patient.fields';
import { OrderProfaFields } from './order-profa.fields';
import { OrderedAnalysis } from './ordered-analysis';
import { errors } from '../../errors';
import { WithSuffix } from '../../type-utils/with-suffix.util';
import { Doctor } from '../doctor';
import { DoctorBase } from '../embeded';
import { OrderBilledTo, OrderSamplingStatus, OrderStatus } from '../enums';

export class Order extends AbstractLablinkOrder {
  @PrimaryGeneratedColumn({ name: 'pkCommande', type: 'int' })
  id!: number;

  @Exclude({ toClassOnly: true })
  doctor?: Doctor;

  @Column({ name: 'fkMedecin' })
  doctorId!: number;

  @IsEnum(OrderBilledTo)
  @IsIn([OrderBilledTo.PATIENT, OrderBilledTo.INSURANCE, OrderBilledTo.OTHER_BILLING, OrderBilledTo.DOCTOR])
  @Column('enum', { nullable: true, enum: OrderBilledTo })
  billedTo?: OrderBilledTo | null;

  @IsEnum(OrderSamplingStatus)
  @IsNotIn([OrderSamplingStatus.SAMPLE_AT_PATIENT])
  @Column('enum', { nullable: true, enum: OrderSamplingStatus })
  samplingStatus?: OrderSamplingStatus | null;

  @IsOptional()
  @Type(() => Invoicing)
  @ValidateNested()
  invoicing?: Invoicing | null;

  @Column('bit', {
    default: () => "'b'0''",
    name: 'TiersPayant',
    transformer: booleanTransformer,
  })
  @IsBoolean()
  @IsOptional()
  thirdPartyPayer?: boolean;

  @Column('smallint', {
    default: () => "'0'",
    name: 'Transmis',
  })
  @IsEnum(OrderStatus)
  @IsIn([OrderStatus.TO_ORDER, OrderStatus.ORDERED])
  status!: OrderStatus;

  @Column('bit', {
    default: () => "'b'0''",
    name: 'Urgent',
    transformer: booleanTransformer,
  })
  @IsBoolean()
  @IsOptional()
  urgent?: boolean;

  @Column('bit', {
    default: () => "'b'0''",
    name: 'summonClient',
    transformer: booleanTransformer,
  })
  @IsBoolean()
  @IsOptional()
  summonClient?: boolean;

  /**
   * The infamous VRef... Can contain anything, and is NOT unique.
   */
  @Column('varchar', { length: 50, name: 'VRef', nullable: true })
  @MaxLength(50)
  @IsOptional()
  reference?: string | null;

  /**
   * Appears unused since August 2020.
   */
  @Column('datetime', { name: 'DateLimite', nullable: true })
  @Type(() => Date)
  @IsOptional()
  @IsDate()
  limitDate?: Date | null;

  /**
   * Signals that the sampling must be done directly at the lab.
   *
   * It appears that orders are not imported in the green software while this flag is set.
   */
  @Column('bit', {
    default: () => "'b'0''",
    name: 'PrelLab',
    transformer: booleanTransformer,
  })
  @IsBoolean()
  @IsOptional()
  sampleAtTheLab?: boolean;

  /**
   * Signals that the sampling must be done at the patient's home.
   *
   * It appears that orders are not imported in the green software while this flag is set.
   */
  @Column('bit', {
    default: () => "'b'0''",
    name: 'PrelPat',
    transformer: booleanTransformer,
  })
  @IsBoolean()
  @IsOptional()
  sampleAtPatientHome?: boolean;

  /**
   * Signals that the invoice should be sent directly to the patient.
   *
   * In this case, insurance data will not be required.
   */
  @Column('bit', {
    default: () => "'b'1''",
    name: 'FactPat',
    transformer: booleanTransformer,
  })
  @IsBoolean()
  @IsOptional()
  invoicePatient?: boolean;

  /**
   * Value only set by the green program when a STD is found
   */
  @Column({
    insert: false,
    length: 1,
    name: 'IST',
    nullable: true,
    readonly: true,
    transformer: {
      from: (src: 'P' | 'H' | null) => (src === null ? src : src === 'P'),
      to: (src: boolean | null) => {
        if (src == null) {
          return null;
        }

        return src ? 'P' : 'H';
      },
    },
    type: 'varchar',
    update: false,
  })
  @IsBoolean()
  @IsOptional()
  readonly foundStd?: boolean | null;

  @Column('varchar', { length: 255, name: 'RemAna', nullable: true })
  @MaxLength(255)
  @IsOptional()
  remark?: string | null;

  /**
   * Comments set by the laboratory on the order.
   *
   * This field should not be modified by the website, only by the lab.
   */
  @Column({
    insert: false,
    length: 255,
    name: 'CommentICC',
    nullable: true,
    readonly: true,
    type: 'varchar',
  })
  @MaxLength(255)
  @IsOptional()
  readonly iccComments?: string | null;

  @Column('varchar', { length: 255, name: 'AutreMateriel', nullable: true })
  @MaxLength(255)
  @IsOptional()
  otherMaterial?: string | null;

  @Column('varchar', { length: 255, name: 'AutresAnalyses', nullable: true })
  @MaxLength(255)
  @IsOptional()
  otherAnalysis?: string | null;

  @CreateDateColumn({
    name: 'DateDemande',
    nullable: true,
    type: 'datetime',
    transformer: dateTransformer,
  })
  @IsDateString()
  @IsOptional()
  requestDate?: string | null;

  @Column('datetime', {
    name: 'DatePrélèvement',
    nullable: true,
    transformer: dateTransformer,
  })
  @IsDateString()
  @IsDefined({ errorName: errors.orders.validation.requireSampleDate })
  @IsOptionalIf<Order>(obj => obj.samplingStatus !== OrderSamplingStatus.SAMPLING_DONE)
  @Transform(({ value }) => {
    const valueTyped = value as string | null;
    return valueTyped ? new Date(valueTyped).toISOString() : valueTyped;
  })
  sampleDate?: string | null;

  @Column(() => OrderPatientFields, { prefix: false })
  @Type(() => OrderPatientFields)
  @ValidateNested({ errorName: errors.orders.validation.requirePatient })
  @IsObject({ errorName: errors.orders.validation.requirePatient })
  // Force instance: https://github.com/typestack/class-validator/issues/1473#issuecomment-1020054553
  @Transform(({ value }) =>
    value
      ? plainToInstance(OrderPatientFields, OrderPatientFields.nullifyIfEmpty(value as OrderPatientFields))
      : undefined,
  )
  patient?: OrderPatientFields;

  @Column('bit', {
    default: () => "'b'0''",
    name: 'AFaxerMed',
    transformer: booleanTransformer,
  })
  @IsBoolean()
  @IsOptional()
  shouldFaxDoctor?: boolean;

  /**
   * Data of the doctor to whom a copy of the report should be sent
   */
  @Column(() => WithSuffix(DoctorBase, 'Med1'), { prefix: false })
  @Type(() => DoctorBase)
  @ValidateNested({ always: true })
  @IsOptional()
  // Force instance: https://github.com/typestack/class-validator/issues/1473#issuecomment-1020054553
  @Transform(({ value }) => plainToInstance(DoctorBase, DoctorBase.nullifyIfEmpty(value as DoctorBase)))
  secondDoctorData?: DoctorBase;

  @Column('bit', {
    default: () => "'b'0''",
    name: `AFaxerMed1`,
    transformer: booleanTransformer,
  })
  @IsBoolean()
  @IsOptional()
  shouldFaxSecondDoctor?: boolean;

  @Column('varchar', { length: 20, name: 'IP', nullable: true })
  @MaxLength(20)
  @IsIP()
  @IsOptional()
  ip?: string | null;

  /**
   * Specifies that a copy of the results should be sent to the patient
   */
  @Column('bit', {
    default: () => "'b'0''",
    name: 'CopiePat',
    transformer: booleanTransformer,
  })
  @IsBoolean()
  @IsOptional()
  copyToPatient!: boolean;

  @ArrayNotEmpty({ errorName: errors.orders.validation.analysisListEmpty })
  @ValidateNested({ each: true })
  @Type(() => OrderedAnalysis)
  @IsArray()
  requestedAnalysis!: OrderedAnalysis[];

  @Column(() => OrderLavignyFields, { prefix: false })
  @Type(() => OrderLavignyFields)
  @ValidateNested()
  @IsOptional()
  lavigny!: OrderLavignyFields;

  @Column(() => OrderCovidFields, { prefix: false })
  @Type(() => OrderCovidFields)
  @ValidateNested()
  @IsOptional()
  covid!: OrderCovidFields;

  /**
   * Profa specific data. Check `isProfa` to see if this should contain data
   */
  @Column(() => OrderProfaFields, { prefix: false })
  @Type(() => OrderProfaFields)
  @ValidateNested()
  @IsOptional()
  profa!: OrderProfaFields;

  /**
   * The department to bill inside the ordering entity.
   *
   * Historically used only for Lavigny, but may be relevant for other mandators.
   *
   * @deprecated replaced by orderingDepartment, which is copied in DepLavigny for compatibility purpose
   */
  @Column('varchar', { length: 20, name: 'DepLavigny', nullable: true })
  @MaxLength(20)
  @IsOptional()
  billingDepartment?: string | null;

  /**
   * The department that makes the order.
   *
   * Used mainly by Lavigny and other big mandators.
   */
  @Column('varchar', { length: 50, name: 'OrderingDepartment', nullable: true })
  @MaxLength(50)
  @IsOptional()
  orderingDepartment?: string | null;

  /**
   * Computed column
   */
  @Column({
    asExpression:
      "CONCAT(IFNULL(`VRef`, ''), ' ', IFNULL(`Nom`, ''), ' ', IFNULL(`Prénom`, ''), ' ', IFNULL(`Nom`, ''), ' ', IFNULL(`NoAssuré`, ''), ' ', IFNULL(`OrderingDepartment`, ''))",
    generatedType: 'STORED',
    insert: false,
    select: false,
    update: false,
  })
  @Index('idx_searchColumn')
  @Exclude()
  searchColumn?: string;

  @Column('bit', {
    default: () => "'b'0''",
    name: 'isAddition',
    transformer: booleanTransformer,
  })
  @IsBoolean()
  @IsOptional()
  isAddition?: boolean;

  /**
   * Checks if this order was made by a doctor linked with Profa
   */
  get isProfa(): boolean {
    return MagicValues.PROFA_DOCTOR_IDS.has(this.doctorId);
  }

  get isCovid(): boolean {
    return (
      this.covid.antigen ||
      this.covid.pcr ||
      this.covid.serology ||
      this.covid.requestCertificate ||
      this.covid.requestSerologyAttestationEnglish ||
      this.covid.requestSerologyAttestationFrench ||
      this.covid.requestSerologyAttestationChinese ||
      this.covid.requestTravelAttestationFrench ||
      this.covid.requestTravelAttestationEnglish ||
      false
    );
  }
}
