import { AxiosError, AxiosResponse } from 'axios';
import { NextRouter } from 'next/router';
import { TFunction } from 'next-i18next';

/** @param next function is used to call the generic notification error */
export type OnError = (error: AxiosError, next: () => void) => void;
/** @param next function is used to auto invalid queries */
export type OnSuccessMutation<T> = (data: T, next: () => Promise<void>) => void;

export type UseGetOptions<ReturnType, IntermediateType = ReturnType> = {
  enabled?: boolean;
  onError?: OnError;

  onSuccess?: (data: ReturnType) => void; // It can be used to make conditional call
  disableRefetching?: boolean;

  select?: (data: IntermediateType) => ReturnType;

  keepPreviousData?: boolean;
  refetchInterval?: number;
};

export interface UseMutationOptions<T> {
  onError?: OnError;
  onSuccess?: OnSuccessMutation<T>;
  disableRefetching?: boolean;
}

export abstract class AbstractApiCallHelper {
  constructor(protected translation: TFunction, protected readonly routerPush: NextRouter['push']) {}

  public onError(error: AxiosError, onError?: OnError): void {
    const next = this.onErrorNext.bind(this, error);

    if (onError) {
      onError(error, next);
      return;
    }
    next();
  }

  public async onSuccessMutation<T>(
    data: AxiosResponse<T>,
    next: () => Promise<void>,
    onSuccess?: OnSuccessMutation<T>,
  ): Promise<void> {
    const entity = data.data;
    if (onSuccess) {
      return onSuccess(entity, next);
    }
    return next();
  }

  public async onSuccessDeleteMutation<IdType = number>(
    id: IdType,
    next: () => Promise<void>,
    onSuccess?: OnSuccessMutation<IdType>,
  ): Promise<void> {
    if (onSuccess) {
      return onSuccess(id, next);
    }
    return next();
  }

  /**
   * The default 'onError' handler, also passed as the `next` parameter when the handler is override
   * @param error received
   */
  protected abstract onErrorNext(error: AxiosError): void;
}
