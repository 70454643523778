import { MagicValues } from '@ch-apptitude-icc/common/shared/entities';
import { DotPath } from '@ch-apptitude-icc/common/shared/type-utils';
import { InsurerSelect } from '@features/insurers/components/input';
import { PatientFormikWrapper } from '@features/orders/components/detail/patient/PatientFormikWrapper';
import { ProfaDoctorSelect } from '@features/orders/components/detail/patient/ProfaDoctorSelect';
import { CreateOrderPatient } from '@features/orders/types/CreateOrderFront';
import { CreateOrderStepPatient } from '@features/orders/types/CreateOrderStepPatient';
import { SSNInput } from '@features/patients/components/input';
import { genderOptions } from '@features/patients/utils';
import { CountrySelect, FormikElementWrapper, Input, PhoneInput, Select, Toggle } from '@features/ui/components';
import { useApi } from '@services/api';
import { useFormikContext } from 'formik';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

type PatientFormProps = {
  /**
   * @default true
   */
  allowAnonymous?: boolean;
};

export const PatientForm = ({ allowAnonymous = true }: PatientFormProps): JSX.Element => {
  const { t } = useTranslation();
  const { values: patientData, setValues } = useFormikContext<CreateOrderStepPatient>();

  const { authApi } = useApi();
  const me = authApi.useGetMe();
  const isProfaDoctor = useMemo(() => MagicValues.PROFA_DOCTOR_IDS.has(me.data?.doctorId ?? -1), [me.data]);

  return (
    <div className="lg:w-1/2">
      <div className="text-md flex items-center md:text-lg">
        <span className="flex-1">{t('pages.patients.newPatientForm.fillData')}</span>

        {allowAnonymous && me.data?.doctor?.allowAnonymousPatients && (
          <>
            <Toggle
              value={!!patientData?.patient?.anonymous}
              onChange={v =>
                void setValues({
                  ...patientData,
                  patient: new CreateOrderPatient(v),
                })
              }
            />

            <span className="pl-2 text-sm">{t('pages.patients.newPatientForm.anonymousPatient')}</span>
          </>
        )}
      </div>

      <div className="grid grid-cols-1 gap-x-4 gap-y-5 pt-5 md:grid-cols-2">
        {me.data?.doctor?.enableDepartment && (
          <>
            <PatientFormikWrapper label={t('entities.patient.department')} name="orderingDepartment">
              <Input type="text" autoFocus />
            </PatientFormikWrapper>
            <div />
          </>
        )}

        <PatientFormikWrapper label={t('entities.patient.reference')} name="patient.externalReference">
          <Input type="text" autoFocus />
        </PatientFormikWrapper>

        <PatientFormikWrapper
          label={t(isProfaDoctor ? 'entities.patient.orderReferenceProfa' : 'entities.patient.orderReference')}
          name="reference"
          required={isProfaDoctor}
        >
          {isProfaDoctor ? <ProfaDoctorSelect /> : <Input type="text" />}
        </PatientFormikWrapper>

        {patientData?.patient?.anonymous && (
          <PatientFormikWrapper
            label={t('entities.patient.birthYear')}
            name="patient.birthYear"
            className="w-3/4"
            required
          >
            <Input type="text" />
          </PatientFormikWrapper>
        )}

        <PatientFormikWrapper label={t('entities.patient.gender.gender')} name="patient.gender" required>
          <Select options={genderOptions(t)} />
        </PatientFormikWrapper>

        {patientData?.patient?.anonymous ? (
          <>
            <PatientFormikWrapper label={t('entities.patient.lastNameInitial')} name="patient.lastname">
              <Input type="text" className="w-1/4" />
            </PatientFormikWrapper>

            <PatientFormikWrapper label={t('entities.patient.firstNameInitial')} name="patient.firstname">
              <Input type="text" className="w-1/4" />
            </PatientFormikWrapper>
          </>
        ) : (
          <>
            {/*
                <PatientFormikWrapper label={t('entities.patient.title.title')} name="patient.firstname">
                  <Select disabled options={titleOptions(t)} />
                </PatientFormikWrapper>
              */}
            {/* Line return in grid */}
            <div />

            <PatientFormikWrapper label={t('entities.patient.lastName')} name="patient.lastname" required>
              <Input type="text" />
            </PatientFormikWrapper>

            <PatientFormikWrapper label={t('entities.patient.firstName')} name="patient.firstname" required>
              <Input type="text" />
            </PatientFormikWrapper>

            <PatientFormikWrapper label={t('entities.patient.birthDate')} name="patient.birthDate" required>
              <Input type="date" className="w-3/4" />
            </PatientFormikWrapper>

            <PatientFormikWrapper label={t('entities.patient.weight')} name="patient.weight">
              <Input type="number" addOnRight="Kg" className="w-3/4" />
            </PatientFormikWrapper>

            <PatientFormikWrapper label={t('entities.patient.email')} name="patient.emailAddress">
              <Input type="text" />
            </PatientFormikWrapper>

            <PatientFormikWrapper label={t('entities.patient.phone')} name="patient.telephoneNumber">
              <PhoneInput />
            </PatientFormikWrapper>

            <PatientFormikWrapper label={t('entities.patient.avsNo')} name="patient.socialSecurityNumber">
              <SSNInput />
            </PatientFormikWrapper>

            <PatientFormikWrapper label={t('entities.patient.insurance')} name="patient.insurerId">
              <InsurerSelect />
            </PatientFormikWrapper>

            <PatientFormikWrapper
              label={t('entities.patient.address.address1')}
              name="patient.address.address1"
              required
            >
              <Input type="text" />
            </PatientFormikWrapper>

            <PatientFormikWrapper label={t('entities.patient.address.address2')} name="patient.address.address2">
              <Input type="text" />
            </PatientFormikWrapper>

            <FormikElementWrapper
              label={`${t('entities.patient.address.zipCode')} / ${t('entities.patient.address.city')}`}
              names={
                ['patient.address.postcode', 'patient.address.city'] satisfies Array<DotPath<CreateOrderStepPatient>>
              }
            >
              <Input type="text" className="block w-1/3" />
              <Input type="text" className="block w-2/3" />
            </FormikElementWrapper>

            <PatientFormikWrapper label={t('entities.patient.address.country')} name="patient.address.countryCode">
              <CountrySelect />
            </PatientFormikWrapper>
          </>
        )}

        {/*
            <PatientFormikWrapper
              label={t('entities.patient.patientRemark')}
              name="remark"
              className="col-span-1 md:col-span-2"
            >
              <TextArea disabled rows={2} />
            </PatientFormikWrapper>
           */}
      </div>
    </div>
  );
};
